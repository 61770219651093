import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        welcome: "Welcome to",
        arlogo_text: "Visit Reggio Emilia with augmented reality through AR Logos and interactive visits",
        arbutton_text: "Frame an AR Logo",
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start the tour",
        tutorial_1: "Point the camera at the Reggio smART AR Logo",
    	tutorial_2: "You will access exclusive digital content: videos, image galleries, information cards and much more ...!",
    },
    it: {
        welcome: "Benvenuti a",
        arlogo_text: "Visita Reggio Emilia con la realtà aumentata tramite gli AR Logo e le visite interattive",
        arbutton_text: "Inquadra un AR Logo",
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Comincia la visita",
        tutorial_1: "Inquadra il logo di Reggio smART",
    	tutorial_2: "Accederai a contenuti digitali esclusivi: video, gallerie di immagini, schede informative e molto altro...!",
    }
});

export default localeStrings;