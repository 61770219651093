import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    gallery_title: "Gallery",
    start_ar_button: "Start AR Scene",
    //Buongiorno, Condivido con te "{title}" scoprilo/a al link {url} e scarica l'app "Design Finder ICG" a questo link https://www.irisceramicagroup.com/designerfinderapp
    share_content_text: "Hi, I share with you \"{title}\" discover it at the link {url} and download the \"Design Finder ICG\" app at this link https://www.irisceramicagroup.com/designerfinderapp",
  },
  it: {
    gallery_title: "Galleria",
    start_ar_button: "Avvia la scena di AR",
    share_content_text: "Buongiorno, Condivido con te \"{title}\" scoprilo/a al link {url} e scarica l'app \"Design Finder ICG\" a questo link https://www.irisceramicagroup.com/designerfinderapp",
  }
});

export default localeStrings;