import React from 'react';
import { CONTENT_VIEW } from 'pikkart-cms';
import { PkCordova, PkCordovaSharing } from 'pikkart-cordova';
import localeStrings from './detail-view-local';
import { Card, CardContent, Link, Block, Preloader } from 'framework7-react';
import ContentHeader from '../content-views/content-elements/content-header';
import InfoContentView from '../content-views/info-content-view';
import ArSceneView from '../content-views/ar-scene-view/ar-scene-view';
import AnalyticsRegister, { ANALYTICS_EVENT } from '../analytics-register';
import Commons from '../commons';
import PlateAssetsView from '../content-views/plate-assets-view/plate-assets-view';
import DiscoverView from '../content-views/discover-view/discover-view';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

export class DetailView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            correlateInfoContentsData: null,
            isFavoriteContent: false,
            starIcon: 'star',
            fullShowImageHeight: '300px',
        };
    }

    render() {
        const item = this.props.item;

        const detailType = this.getDetailType(item); //tutto visibile o tutta immagine visible

        const detailContent = (
            this.props.isDetailVisible ?
                this.getContentViewLayout(item) : null)



        let image = "";
        if (detailType === CARD_VIEW_TYPE.FULL_SHOW_IMAGE) {
            const imageHeight = this.props.isDetailVisible ? this.state.fullShowImageHeight : '300px'

            image = (<div id={'card-image-' + item.content.id} className={"closed-card-width"} style={{ height: imageHeight, textAlign: 'center' }}>
                <img alt={item.content.title} onClick={() => { this.onImageClick(item.content.imageUrl) }} src={item.content.imageUrl} style={{ height: '100%', objectFit: 'contain' }} />
            </div>)
        }
        else if (detailType === CARD_VIEW_TYPE.HIDING_IMAGE) {
            image = (<div id={'card-image-' + item.content.id} className={"closed-card-width"} style={{ textAlign: 'center' }}>
                <img alt={item.content.title} onClick={() => { this.onImageClick(item.content.imageUrl) }} src={item.content.imageUrl} style={{ width: '100%' }} />
            </div>)
        }

        const options = {
            buttons: {
                showAutoplayButton: false,
                showCloseButton: true,
                showDownloadButton: false,
                showFullscreenButton: false,
                showThumbnailsButton: false,
            },
            thumbnails: {
                showThumbnails: false,
            }
        }

        return (
            <div id="content-detail-view">
                {/* <Block style={{ padding: 0, margin: 0 }}> */}
                <ContentHeader
                    id={'content-header-' + item.content.id}
                    isDetailVisible={this.props.isDetailVisible}
                    showCloseButton={this.props.showCloseButton}
                    closeButtonFn={this.props.closeButtonFn}
                    content={item.content}
                    brand={item.brand}
                    skin={item.skin} />

                <div>
                    <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                            {this.props.isImageVisible ? image :
                                <Preloader style={{
                                    position: 'relative',
                                    left: window.outerWidth < window.outerHeight ? '45%' : '22%',
                                    transform: 'translate(-50%, 530%)'
                                }} />
                            }
                        </SRLWrapper>
                    </SimpleReactLightbox>
                    <p />
                    {detailContent}
                </div>
            </div>
        );
    }

    errorRetrievingContent = (
        <Block style={{ padding: 0, margin: 0 }}>
            <Link
                cardClose
                color="Black"
                className="card-opened-fade-in"
                style={{ position: 'absolute', right: '15px', top: '15px' }}
                iconF7="xmark_circle_fill" />
            <Card>
                <CardContent style={{ textAlign: 'center' }}>
                    error retreiving content
                </CardContent>
            </Card>
        </Block>
    )

    getContentViewLayout = (item) => {
        if (item) {
            switch (item.content.ctnView) {
                case CONTENT_VIEW.DISCOVER_MODEL:
                    return this.getDiscoverContentViewTab(item.content);
                case CONTENT_VIEW.INFOCONTENT:
                    return this.getInfoContentViewTab(item.content);
                case CONTENT_VIEW.MARKERLESS:
                    return this.getInfoContentViewTab(item.content);
                case CONTENT_VIEW.DISCOVER_KEYPOINT:
                    return this.errorRetrievingContent;
                case CONTENT_VIEW.PLACE:
                    return this.errorRetrievingContent;
                case CONTENT_VIEW.ITINERARY:
                    return this.errorRetrievingContent;
                case CONTENT_VIEW.AR_SCENE:
                    return this.getArScenesContentViewTab(item.content);
                case -12: //lastre asset recognition
                    return this.getAssetRecognitionView(item.brand, item.content);
                default:
                    return this.errorRetrievingContent;
            }
        }
    }

    getDetailType = (item) => {
        if (item) {
            switch (item.content.ctnView) {
                case CONTENT_VIEW.DISCOVER_MODEL:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
                case CONTENT_VIEW.INFOCONTENT:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
                case CONTENT_VIEW.MARKERLESS:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
                case CONTENT_VIEW.DISCOVER_KEYPOINT:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
                case CONTENT_VIEW.PLACE:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
                case CONTENT_VIEW.ITINERARY:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
                case CONTENT_VIEW.AR_SCENE:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
                case -12: //lastre asset recognition
                    return CARD_VIEW_TYPE.FULL_SHOW_IMAGE;
                default:
                    return CARD_VIEW_TYPE.HIDING_IMAGE;
            }
        }
    }

    getInfoContentViewTab = (content) => {

        return <InfoContentView
            content={content}
            shareContentHandler={this.shareContent}
            galleryTitle={localeStrings.gallery_title} />
    }

    getArScenesContentViewTab = (content) => {
        return <ArSceneView
            content={content}
            arSceneButtonText={localeStrings.start_ar_button}
            shareContentHandler={this.shareContent}
            galleryTitle={localeStrings.gallery_title}
            currentCompany={this.props.currentCompany} />
    }

    getDiscoverContentViewTab = (content) => {
        return <DiscoverView
            content={content}
            discoverButtonText={localeStrings.start_discover_button}
            shareContentHandler={this.shareContent}
            galleryTitle={localeStrings.gallery_title} />
    }

    getAssetRecognitionView = (brand, content) => {
        console.log('result view asset recognition');
        return <PlateAssetsView
            brand={brand}
            content={content}
            contentHeightDefinedHandler={this.contentHeightDefined}
            shareContentHandler={this.shareContent}
            galleryTitle={localeStrings.gallery_title}
            onInfoClickHandler={this.props.onInfoClickHandler} />
    }

    shareContent = (url) => {

        //in base alla ctnView dovrebbe fare una cosa diversa la condivisione
        const item = this.props.item;

        AnalyticsRegister.logEvent(
            ANALYTICS_EVENT.SHARE_URL_CLICK,
            {
                ContentName: item.content.title,
                ContentId: item.content.id,
                ContentType: Commons.getStringContentView(item.content.ctnView),
                Url: item.content.googleUrl
            });
        if (PkCordova.isCordova()) {
            PkCordovaSharing.share(
                localeStrings.formatString(localeStrings.share_content_text, { title: item.content.title, url: url }),
                "Design finder ICG app - discover \"" + item.content.title + "\"",
                null,
                "");
        }
        else {
            const shareData = {
                title: "Design finder ICG app - discover \"" + item.content.title + "\"",
                text: localeStrings.formatString(localeStrings.share_content_text, { title: item.content.title, url: url }),
            }
            navigator.share(shareData);
        }
    }

    onImageClick = (url) => {
        console.log('image click ', url);
    }

    contentHeightDefined = (height) => {
        const contentHeader = document.getElementById('content-header-' + this.props.item.content.id)
        if (contentHeader) {
            const contentHeaderHeight = contentHeader.clientHeight;

            const imageHeight = window.document.body.offsetHeight - height - contentHeaderHeight;

            this.setState({ fullShowImageHeight: imageHeight + 'px' })
        }
    }
}

export const CARD_VIEW_TYPE = {
    HIDING_IMAGE: 1,
    FULL_SHOW_IMAGE: 3
};

export default DetailView;