import React from 'react';
import { connect } from 'react-redux';
import { f7, Link, Block, Button, Popup, View, Page, Navbar, NavRight, NavLeft } from 'framework7-react';
import MapView from '../content-elements/map-view';
import ContactList from '../content-elements/contact-list';
import ImageGallery from '../content-elements/image-gallery';
import localeStrings from './discover-view-local';
import * as actions from '../../redux/actions';
import { PkBabylon } from 'pikkart-cordova';
import { RaLog } from 'pikkart-remote-assistance';
import MobileDetect from 'mobile-detect';
import TutorialHelper from '../../helpers/tutorial-helper';
import { PkCordovaARSdk, PkCordovaPermissions } from 'pikkart-cordova';

class DiscoverView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTutorial: true,
        };
    }

    render() {

        const content = this.props.content;

        let discoverButton = <Button
            style={{ marginLeft: '85px', marginRight: '85px' }}
            large
            fill
            popupOpen={".popup-tutorial-discover" + content.id}>
            {this.props.discoverButtonText}
        </Button>
        if (this.props.skippedDiscoverTutorial) {
            discoverButton = <Button style={{ marginLeft: '85px', marginRight: '85px' }}
                large
                fill
                onClick={(e) => { this.startDiscoverExperience(content.extlId) }}>
                {this.props.discoverButtonText}
            </Button>
        }

        const shareLink = (content.googleUrl && content.googleUrl !== "" ?
            <Link
                className='material-icons'
                iconIos="material:share"
                iconMd="material:share"
                color='black'
                onClick={() => { this.props.shareContentHandler(content.googleUrl) }} /> :
            "");
        const discoverPopup = (
            <Popup push className={"popup-tutorial-discover" + content.id}
                tabletFullscreen={true} closeOnEscape={true}>
                <View>
                    <Page>
                        <Navbar transparent={false}>
                            <NavLeft>
                                <Link popupClose iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                            </NavLeft>
                            <NavRight>
                                {JSON.parse(process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR) ?
                                    <img slot="media" src={'./img/logo/logo.png'}
                                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' /> :

                                    <img slot="media" src='./img/spacing_icon.png'
                                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />}

                            </NavRight>
                        </Navbar>

                        {TutorialHelper.getDiscoverContentDetailTutorial(this.toggleClicked, () => this.startDiscoverExperience(content.extlId))}

                    </Page>
                </View>
            </Popup>
        )

        let contentDescr = "";
        contentDescr = content.descr.split("\n").map(function (item, idx) {
            return (
                <span key={idx}>
                    {item}
                    <br />
                </span>
            )
        })

        return (
            <Block style={{ marginTop: 0 }}>

                <div className="video-container">

                    <div style={{ textAlign: 'right' }}>
                        {shareLink}
                        {discoverButton}
                    </div>
                </div>

                <div>
                    <p>{contentDescr}</p>

                    {((content.lat !== 0 && content.long !== 0) ? <MapView content={content} /> : null)}

                    <ContactList content={content} />

                </div>


                <ImageGallery
                    imageArray={content.imageUrls}
                    galleryTitle={this.props.galleryTitle} />

                {discoverPopup}
            </Block>
        )
    }


    toggleClicked = (selected) => {
        this.props.setSkippedDiscoverTutorial(selected);
        localStorage.setItem('skip_discover_tutorial', selected);
    }

    startArScene = (contentId) => {
        RaLog.log('Start ar scene', this.props.content.id);
        let apiUrl = process.env.REACT_APP_CMS_API_URL;
        apiUrl = apiUrl.replace(/(^\w+:|^)\/\//, '');
        //const sceneContentId = this.props.content.id;
        const loadingTime = 6000;
        const loadingText = localeStrings.loading_text;
        const sceneLoadErrorMessage = localeStrings.scene_loading_error_message;
        const mediaLoadErrorMessage = localeStrings.media_loading_error_message;
        let lockRotationType = 1;//portrait = 1, landscape = 0
        const md = new MobileDetect(window.navigator.userAgent);
        if (md.phone()) {
            lockRotationType = 1;
        }
        else {
            lockRotationType = 0;
        }
        const loadingScreenBackgroundColor = "#4D4D4D";
        const loadingBarColor = "#FF7900";
        const emptyLoadingBarColor = "#FFFFFF";

        const screenshotDir = process.env.REACT_APP_SCREENSHOT_DIR;
        const screenshotName = process.env.REACT_APP_SCREENSHOT_NAME;


        PkBabylon.startBabylon(
            apiUrl,
            contentId,
            loadingTime,
            loadingText,
            sceneLoadErrorMessage,
            mediaLoadErrorMessage,
            lockRotationType,
            loadingScreenBackgroundColor,
            loadingBarColor,
            emptyLoadingBarColor,
            screenshotDir,
            screenshotName);

        /*AnalyticsRegister.logEvent(ANALYTICS_EVENT.START_AR_DISCOVER,
            {
                SceneName: this.props.content.title,
                SceneContentId: contentId,
                CompanyName: this.props.currentCompany.Name,
                CompanyId: this.props.currentCompany.Id,
                AdminName: this.props.currentCompany.Admin.Name,
                AdminId: this.props.currentCompany.Admin.Id,
            });*/
    }


    startDiscoverExperience = (modelId) => {
        let _dialog = null;
        const startDiscoverFragment = (modelId) => {
            // this.props.setActivityPaused(true);
            PkCordovaARSdk.startDiscover(process.env.REACT_APP_CMS_API_URL, modelId, '?contentid=', "?keypointid=", process.env.REACT_APP_DISCOVER_KEYPOINTS_COLOR);
        }

        const downloadDiscoverModel = (modelId) => {
            PkCordovaPermissions.askStoragePermission().then((status) => {
                openDeterminedProgress();
                PkCordovaARSdk.downloadDiscoverModel(process.env.REACT_APP_CMS_API_URL, modelId, true, finished, error, progress);
            }).catch((err) => {
                RaLog.error('Download discover model', err);
                openErrorDialog(err);
            });

            const finished = () => {
                RaLog.log('download models finished');
                _dialog.setText('Loading models 100 %');

                closeDialog();

                //const integerModelId = modelId.substring(modelId.lastIndexOf("_") + 1, modelId.lenght);
                startDiscoverFragment(modelId);
            };

            const progress = (progressValue) => {
                RaLog.log('download model progress ' + progressValue);
                if (_dialog)
                    _dialog.setProgress(progressValue);
                _dialog.setText('Loading models ' + Math.round(progressValue) + ' %');
            };

            const error = () => {
                _dialog.setText('download models error');
                closeDialog();
            };

            const openDeterminedProgress = () => {
                const app = f7;
                let progress = 0;
                _dialog = app.dialog.progress('Download', progress);
                _dialog.setText('Loading models');
            }

            const openErrorDialog = (error) => {
                const app = f7;
                _dialog = app.dialog.alert(error);
                _dialog.setTitle('Error');
            }
        }

        const closeDialog = () => {
            setTimeout(function () {
                _dialog.close();
            }, this.state.dialogClosingTime)
        }

        //const intModelId = modelId.substring(modelId.lastIndexOf("_") + 1, modelId.lenght);
        downloadDiscoverModel(modelId);
    }

    componentDidMount = () => {

        const skippedDiscoverTutorial = JSON.parse(localStorage.getItem('skip_discover_tutorial'));
        if (skippedDiscoverTutorial) {
            this.props.setSkippedDiscoverTutorial(skippedDiscoverTutorial);
        }
        else {
            this.props.setSkippedDiscoverTutorial(false);
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        skippedDiscoverTutorial: state.app.skippedDiscoverTutorial,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSkippedDiscoverTutorial: (skippedDiscoverTutorial) => dispatch(actions.setSkippedDiscoverTutorial(skippedDiscoverTutorial)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverView);