"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaAnalytics = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaAnalytics = function PkCordovaAnalytics() {
  _classCallCheck(this, PkCordovaAnalytics);
};

exports.PkCordovaAnalytics = PkCordovaAnalytics;

_defineProperty(PkCordovaAnalytics, "logEvent", function (name, params) {
  if (cordova.plugins.firebase && cordova.plugins.firebase.analytics) cordova.plugins.firebase.analytics.logEvent(name, params); //{param1: "value1"}
});

_defineProperty(PkCordovaAnalytics, "setCurrentScreen", function (name) {
  if (cordova.plugins.firebase && cordova.plugins.firebase.analytics) cordova.plugins.firebase.analytics.setCurrentScreen(name);
});

var _default = PkCordovaAnalytics;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaApiAuth = void 0;

var _luxon = require("luxon");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaApiAuth = function PkCordovaApiAuth() {
  _classCallCheck(this, PkCordovaApiAuth);
};

exports.PkCordovaApiAuth = PkCordovaApiAuth;

_defineProperty(PkCordovaApiAuth, "createRequestSignature", function (requestParameters, date) {
  return new Promise(function (resolve, reject) {
    var requestAuthentication = window.cordova.plugins.CloudService;
    requestAuthentication.createSignature([requestParameters, date], success, error);

    function error(errorMessage) {
      reject(errorMessage);
    }

    function success(serverSignature) {
      resolve(serverSignature);
    }
  });
});

_defineProperty(PkCordovaApiAuth, "setRequestHeaders", function (axiosParams) {
  console.log('setRequestHeaders - ', axiosParams);
  return new Promise(function (resolve, reject) {
    if (RaCordova.isCordova()) {
      var millisecondsDate = Date.now();
      RaCordovaAuthentication.createServerSignature(axiosParams, millisecondsDate).then(function (serverSignature) {
        axiosParams.headers.Authorization = serverSignature;

        var pkDate = _luxon.DateTime.fromMillis(millisecondsDate).toUTC().toFormat("y-LL-dd'T'H:mm:ss");

        axiosParams.headers.PkDate = pkDate;
        resolve(axiosParams);
      })["catch"](function (error) {
        reject(error);
      });
    } else {
      resolve(axiosParams);
    }
  });
});

var _default = PkCordovaApiAuth;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaARSdk = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * @class Metodi per la gestione delle funzioni di realtà aumentata (AR Discover, AR Logo, Image recognition, ecc...) 
 * @hideconstructor
 */
var PkCordovaARSdk = function PkCordovaARSdk() {
  _classCallCheck(this, PkCordovaARSdk);
};

exports.PkCordovaARSdk = PkCordovaARSdk;

_defineProperty(PkCordovaARSdk, "startRecognition", function (apiUrl, cmsContentPageUrl, enableGlobalRecognition) {
  return new Promise(function (resolve, reject) {
    if (window.cordova.plugins.pikkart) {
      window.cordova.plugins.pikkart.startRecognition([apiUrl, cmsContentPageUrl, enableGlobalRecognition], success, error);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject('Start recognition error ', msg);
    }

    function success(status) {
      resolve(status);
    }
  });
});

_defineProperty(PkCordovaARSdk, "startDiscover", function (apiUrl, uniqueId, urlMarkerless, urlKeypoint, circleColor) {
  return new Promise(function (resolve, reject) {
    if (window.cordova.plugins.pikkart) {
      window.cordova.plugins.pikkart.startDiscover([apiUrl, uniqueId, urlMarkerless, urlKeypoint, circleColor], success, error);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject('Start discover error ', msg);
    }

    function success(status) {
      resolve(status);
    }
  });
});

_defineProperty(PkCordovaARSdk, "downloadMarkers", function (apiUrl, userId, finished, error, progress) {
  if (window.cordova.plugins.pikkart) {
    window.cordova.plugins.pikkart.downloadMarkers([apiUrl, userId], success, errorFn);
  } else {
    errorFn('plugin not found');
  }

  function errorFn(msg) {
    error(msg);
  }

  function success(progressValue) {
    if (progressValue == 100) {
      finished();
    } else {
      progress(progressValue);
    } //resolve(progress);

  }
});

_defineProperty(PkCordovaARSdk, "downloadDiscoverModels", function (apiUrl, finished, error, progress) {
  if (window.cordova.plugins.pikkart) {
    window.cordova.plugins.pikkart.downloadDiscoverModels([apiUrl], success, errorFn);
  } else {
    errorFn('plugin not found');
  }

  function errorFn(msg) {
    error(msg);
  }

  function success(progressValue) {
    if (progressValue == 100) {
      finished();
    } else {
      progress(progressValue);
    } //resolve(progress);

  }
});

_defineProperty(PkCordovaARSdk, "downloadDiscoverModel", function (apiUrl, modelId, downloadMarkersToo, finished, error, progress) {
  if (window.cordova.plugins.pikkart) {
    window.cordova.plugins.pikkart.downloadDiscoverModel([apiUrl, modelId, downloadMarkersToo], success, errorFn);
  } else {
    errorFn('plugin not found');
  }

  function errorFn(msg) {
    error(msg);
  }

  function success(progressValue) {
    if (progressValue == 100) {
      finished();
    } else {
      progress(progressValue);
    } //resolve(progress);

  }
});

_defineProperty(PkCordovaARSdk, "closeModalView", function () {
  if (window.cordova.plugins.pikkart) {
    window.cordova.plugins.pikkart.closeModalView(null, success, error);
  } else {
    error('plugin not found');
  }

  function error(msg) {
    reject('Close ModalView error ', msg);
  }

  function success(status) {
    resolve(status);
  }
});

var _default = PkCordovaARSdk;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaAuthentication = void 0;

var _luxon = require("luxon");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaAuthentication = function PkCordovaAuthentication() {
  _classCallCheck(this, PkCordovaAuthentication);
};

exports.PkCordovaAuthentication = PkCordovaAuthentication;

_defineProperty(PkCordovaAuthentication, "createServerSignature", function (requestParameters, date) {
  return new Promise(function (resolve, reject) {
    var requestAuthentication = window.cordova.plugins.CloudService;
    requestAuthentication.createSignature([requestParameters, date], success, error);

    function error(errorMessage) {
      reject(errorMessage);
    }

    function success(serverSignature) {
      resolve(serverSignature);
    }
  });
});

_defineProperty(PkCordovaAuthentication, "setCmsHeaders", function (axiosParams) {
  console.log('setCmsHeaders - ', axiosParams);
  return new Promise(function (resolve, reject) {
    if (PkCordova.isCordova()) {
      var millisecondsDate = Date.now();
      PkCordovaAuthentication.createServerSignature(axiosParams, millisecondsDate).then(function (serverSignature) {
        axiosParams.config.headers.Authorization = serverSignature;

        var pkDate = _luxon.DateTime.fromMillis(millisecondsDate).toUTC().toFormat("y-LL-dd'T'H:mm:ss");

        axiosParams.config.headers.PkDate = pkDate;
        resolve(axiosParams);
      })["catch"](function (error) {
        reject(error);
      });
    } else {
      resolve(axiosParams);
    }
  });
});

_defineProperty(PkCordovaAuthentication, "getDeviceId", function () {
  return new Promise(function (resolve, reject) {
    if (window.cordova && window.cordova.plugins && window.cordova.plugins.CloudService) {
      var requestAuthentication = window.cordova.plugins.CloudService;
      requestAuthentication.getDeviceId(success, error);
    }

    function error(msg) {
      reject('getDeviceId ', msg);
    }

    function success(status) {
      resolve(status);
    }
  });
});

var _default = PkCordovaAuthentication;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PkBabylon = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkBabylon = function PkBabylon() {
  _classCallCheck(this, PkBabylon);
};

exports.PkBabylon = PkBabylon;

_defineProperty(PkBabylon, "startBabylon", function (apiUrl, sceneContentId, loadingTime, loadingText, sceneLoadErrorMessage, mediaLoadErrorMessage, lockRotationType, loadingScreenBackgroundColor, loadingBarColor, emptyLoadingBarColor, screenshotDir, screenshotName) {
  return new Promise(function (resolve, reject) {
    if (window.cordova && window.cordova.plugins.babylon && window.cordova.plugins.babylon.pikkart) {
      window.cordova.plugins.babylon.pikkart.startBabylon([apiUrl, sceneContentId, loadingTime, loadingText, sceneLoadErrorMessage, mediaLoadErrorMessage, lockRotationType, loadingScreenBackgroundColor, loadingBarColor, emptyLoadingBarColor, screenshotDir, screenshotName], success, error);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject('Start babylon error ', msg);
    }

    function success(status) {
      resolve(status);
    }
  });
});
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaBeaconReceiver = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaBeaconReceiver = function PkCordovaBeaconReceiver() {
  _classCallCheck(this, PkCordovaBeaconReceiver);
};

exports.PkCordovaBeaconReceiver = PkCordovaBeaconReceiver;

_defineProperty(PkCordovaBeaconReceiver, "startBeaconSearch", function (apiUrl, authorization, pkDate, regions) {
  return new Promise(function (resolve, reject) {
    if (window.cordova.plugins.pikkart && window.cordova.plugins.pikkart.beacon) {
      window.cordova.plugins.pikkart.beacon.startBeaconSearch(success, error, [apiUrl, authorization, pkDate, regions]);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject('error ', msg);
    }

    function success(beacon) {
      resolve(beacon);
    }
  });
});

_defineProperty(PkCordovaBeaconReceiver, "stopBeaconSearch", function () {
  return new Promise(function (resolve, reject) {
    if (window.cordova.plugins.pikkart && window.cordova.plugins.pikkart.beacon) {
      window.cordova.plugins.pikkart.beacon.stopBeaconSearch(success, error);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject('error ', msg);
    }

    function success() {
      resolve();
    }
  });
});

_defineProperty(PkCordovaBeaconReceiver, "isBeaconSearchEnabled", function () {
  return new Promise(function (resolve, reject) {
    function error(errorMessage) {
      reject(errorMessage);
    }

    function success() {
      resolve();
    }

    if (window.cordova.plugins.pikkart && window.cordova.plugins.pikkart.beacon) {
      window.cordova.plugins.pikkart.beacon.isBeaconSearchEnabled(success, error);
    } else {
      error('plugin not found');
    }
  });
});

var _default = PkCordovaBeaconReceiver;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaCameraSettings = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaCameraSettings = function PkCordovaCameraSettings() {
  _classCallCheck(this, PkCordovaCameraSettings);
};

exports.PkCordovaCameraSettings = PkCordovaCameraSettings;

_defineProperty(PkCordovaCameraSettings, "openCamera", function (resizeResolution, textArray) {
  return new Promise(function (resolve, reject) {
    console.log(window.cordova.plugins);

    if (window.cordova.plugins && window.cordova.plugins.PikkartCameraSettingsManager) {
      window.cordova.plugins.PikkartCameraSettingsManager.openCamera([resizeResolution, textArray], success, error);
    }
    /*if (window.cordova.plugins.pikkart && window.cordova.plugins.pikkart.image) {
        window.cordova.plugins.pikkart.image.measureImageQualityRaw([base64Image, width, height, minFocusQuality, minContrastQuality], success, error);
    }
    else {
        error('plugin not found');
    }*/


    function error(error) {
      reject(error);
    }

    function success(status) {
      resolve(status);
    }
  });
});

var _default = PkCordovaCameraSettings;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaDevice = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * @class Metodi per la gestione delle funzioni legate al device
 * @hideconstructor
 */
var PkCordovaDevice = function PkCordovaDevice() {
  _classCallCheck(this, PkCordovaDevice);
};

exports.PkCordovaDevice = PkCordovaDevice;

_defineProperty(PkCordovaDevice, "enableProximitySensor", function () {
  if (window.navigator.proximity) {
    window.navigator.proximity.enableSensor();
    window.navigator.proximity.enableProximityScreenOff();
  }
});

_defineProperty(PkCordovaDevice, "disableProximitySensor", function () {
  if (window.navigator.proximity) {
    window.navigator.proximity.disableProximityScreenOff();
    window.navigator.proximity.disableSensor();
  }
});

var _default = PkCordovaDevice;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaImageTesting = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaImageTesting = function PkCordovaImageTesting() {
  _classCallCheck(this, PkCordovaImageTesting);
};

exports.PkCordovaImageTesting = PkCordovaImageTesting;

_defineProperty(PkCordovaImageTesting, "measureImageQualityRaw", function (base64Image, width, height, minFocusQuality, minContrastQuality) {
  return new Promise(function (resolve, reject) {
    if (window.cordova.plugins.pikkart && window.cordova.plugins.pikkart.image) {
      window.cordova.plugins.pikkart.image.measureImageQualityRaw([base64Image, width, height, minFocusQuality, minContrastQuality], success, error);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject('error ', msg);
    }

    function success(status) {
      resolve(status);
    }
  });
});

_defineProperty(PkCordovaImageTesting, "measureImageQualityJpg", function (base64Image, width, height, minFocusQuality, minContrastQuality) {
  return new Promise(function (resolve, reject) {
    if (window.cordova.plugins.pikkart && window.cordova.plugins.pikkart.image) {
      window.cordova.plugins.pikkart.image.measureImageQualityJpg([base64Image, width, height, minFocusQuality, minContrastQuality], success, error);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject(msg);
    }

    function success(status) {
      resolve(status);
    }
  });
});

_defineProperty(PkCordovaImageTesting, "pickImageFromGallery", function () {
  return new Promise(function (resolve, reject) {
    if (window.cordova.plugins.pikkart && window.cordova.plugins.pikkart.image) {
      window.cordova.plugins.pikkart.image.pickImageFromGallery(success, error);
    } else {
      error('plugin not found');
    }

    function error(msg) {
      reject('error ', msg);
    }

    function success(status) {
      resolve(status);
    }
  });
});

var _default = PkCordovaImageTesting;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordova = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordova = function PkCordova() {
  _classCallCheck(this, PkCordova);
};

exports.PkCordova = PkCordova;

_defineProperty(PkCordova, "isCordova", function () {
  if (window.cordova && window.cordova.version) {
    //console.log("we are in cordova");
    return true;
  } //console.log("we are NOT in cordova"); 


  return false;
});

_defineProperty(PkCordova, "getPlatform", function () {
  return window.cordova.platformId;
});

_defineProperty(PkCordova, "init_cordova", function () {// TODO Aggiungere qui inizializzazioni di servizi
});

var _default = PkCordova;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaNet = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaNet = function PkCordovaNet() {
  _classCallCheck(this, PkCordovaNet);
};

exports.PkCordovaNet = PkCordovaNet;

_defineProperty(PkCordovaNet, "openWiFiSettings", function (successCallback, errorCallBack) {
  var settings = window.cordova.plugins.settings;

  if (settings != null) {
    settings.open("wifi", function () {
      console.log('opened settings');
      successCallback(true);
    }, function () {
      console.log('failed to open settings');
      errorCallBack(false);
    });
  } else {
    console.log('settings null');
    errorCallBack(false);
  }
});

_defineProperty(PkCordovaNet, "getConnectionInfo", function (successCallback, errorCallBack) {
  var WifiManager = cordova.plugins.WifiManager;

  if (WifiManager != null) {
    WifiManager.getConnectionInfo(function (err, connInfo) {
      if (err == null) {
        console.log(connInfo);
        successCallback(connInfo);
      } else {
        console.log('err ' + err);
        errorCallBack(err);
      }
    });
  } else {
    errorCallBack("err");
  }
});

_defineProperty(PkCordovaNet, "getDhcpInfo", function (successCallback, errorCallBack) {
  var WifiManager = cordova.plugins.WifiManager;

  if (WifiManager != null) {
    WifiManager.getDhcpInfo(function (err, dhcpInfo) {
      if (err == null) {
        console.log(dhcpInfo);
        successCallback(dhcpInfo);
      } else {
        console.log('err ' + err);
        errorCallBack(err);
      }
    });
  } else {
    errorCallBack("err");
  }
});

_defineProperty(PkCordovaNet, "startWifiScan", function (successCallback, errorCallBack) {
  var WifiManager = cordova.plugins.WifiManager;

  if (WifiManager != null) {
    WifiManager.startScan(function (err, scanSuccess) {
      if (err == null) {
        console.log(scanSuccess);
        successCallback(scanSuccess);
      } else {
        console.log('err ' + err);
        errorCallBack(err);
      }
    });
  } else {
    errorCallBack("err");
  }
});

_defineProperty(PkCordovaNet, "wifiScanResults", function (successCallback, errorCallBack) {
  var WifiManager = cordova.plugins.WifiManager;

  if (WifiManager != null) {
    WifiManager.getScanResults(function (err, scanResults) {
      if (err == null) {
        successCallback(scanResults);
      } else {
        errorCallBack(err);
      }
    });
  } else {
    errorCallBack("err");
  }
});

_defineProperty(PkCordovaNet, "isWifiHotspotEnabled", function (successCallback, errorCallBack) {
  console.log('isWiFiHotSpotEnabled');
  var WifiManager = window.cordova.plugins.WifiManager;

  if (WifiManager != null) {
    WifiManager.isWifiApEnabled(function (err, result) {
      if (err != null) {
        errorCallBack(err);
      } else if (result != null) {
        successCallback(result);
      }
    });
  } else {
    errorCallBack("");
  }
});

_defineProperty(PkCordovaNet, "getWifiApState", function (successCallback, errorCallBack) {
  console.log('getWifiApState');
  var WifiManager = window.cordova.plugins.WifiManager;

  if (WifiManager != null) {
    WifiManager.getWifiApState(function (err, result) {
      if (err != null) {
        errorCallBack(err);
      } else if (result != null) {
        successCallback(result);
      }
    });
  } else {
    errorCallBack("");
  }
});

_defineProperty(PkCordovaNet, "getListOfConnectedDevice", function (successCallback, errorCallBack) {
  console.log('getListOfConnectedDevice');
  var WifiManager = cordova.plugins.WifiManager;

  if (WifiManager != null) {
    WifiManager.getListOfConnectedDevice(function (err, result) {
      console.log(result);

      if (err != null) {
        errorCallBack(err);
      } else if (result != null) {
        successCallback(result);
      }
    });
  } else {
    errorCallBack("");
  }
});

var _default = PkCordovaNet;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaNotifications = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaNotifications = function PkCordovaNotifications() {
  _classCallCheck(this, PkCordovaNotifications);
};

exports.PkCordovaNotifications = PkCordovaNotifications;

_defineProperty(PkCordovaNotifications, "init", function (onMessageEventHandler) {
  console.log("init_cordova_notifications");

  if (window.hasOwnProperty("cordova")) {
    var firebase = window.cordova.plugins.firebase;

    if (firebase && firebase.messaging) {
      window.cordova.plugins.firebase.messaging.requestPermission({
        forceShow: true
      }).then(function () {
        console.log("You'll get foreground notifications when a push message arrives");
      });
      window.cordova.plugins.firebase.messaging.getToken().then(function (token) {
        console.log("Got device token: ", token);
        PkCordovaNotifications.setFirebaseToken(token);
      });
      window.cordova.plugins.firebase.messaging.onTokenRefresh(function () {
        console.log("Device token updated");
        window.cordova.plugins.firebase.messaging.getToken().then(function (token) {
          console.log("Got device token: ", token);
          PkCordovaNotifications.setFirebaseToken(token);
        });
      });
      window.cordova.plugins.firebase.messaging.onMessage(function (payload) {
        console.log("New foreground FCM message: ", payload);
        onMessageEventHandler(payload);
      });
      window.cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
        console.log("New background FCM message: ", payload);
        onMessageEventHandler(payload);
      });
    }
  }
});

_defineProperty(PkCordovaNotifications, "setFirebaseToken", function (token) {
  if (PkCordova.isCordova()) {
    localStorage.setItem('firebaseToken', token);
  }
});

_defineProperty(PkCordovaNotifications, "getFirebaseToken", function () {
  if (PkCordova.isCordova()) return localStorage.getItem('firebaseToken');else return null;
});

_defineProperty(PkCordovaNotifications, "goToNotificationSettings", function (notificationSettingsOnResume) {
  var notificationManager = window.cordova.plugins.NotificationManager;

  if (notificationManager) {
    window.cordova.plugins.NotificationManager.goToNotificationSettings(notificationSettingsOnResume);
  }
});

_defineProperty(PkCordovaNotifications, "areNotificationsEnabled", function () {
  return new Promise(function (resolve, reject) {
    function error(errorMessage) {
      reject(errorMessage);
    }

    function success() {
      resolve();
    }

    var notificationManager = window.cordova.plugins.NotificationManager;

    if (notificationManager) {
      window.cordova.plugins.NotificationManager.areNotificationsEnabled(success, error);
    } else {
      error('cannot find plugin');
    }
  });
});

_defineProperty(PkCordovaNotifications, "getReceivedMessages", function () {
  return new Promise(function (resolve, reject) {
    function success(messages) {
      resolve(messages);
    }

    function error(errorMessage) {
      reject(errorMessage);
    }

    var firebase = window.cordova.plugins.firebase;

    if (firebase && firebase.messaging) {
      window.cordova.plugins.firebase.messaging.getReceivedMessages().then(function (messages) {
        success(messages);
      });
    } else {
      error('cannot find plugin');
    }
  });
});

_defineProperty(PkCordovaNotifications, "getLatestNotificationMessage", function () {
  return new Promise(function (resolve, reject) {
    function error(errorMessage) {
      reject(errorMessage);
    }

    function success(latestNotificationMessage) {
      if (latestNotificationMessage) {
        resolve(latestNotificationMessage);
      } else {
        reject('app started without notification');
      }
    }

    var notificationManager = window.cordova.plugins.NotificationManager;

    if (notificationManager) {
      window.cordova.plugins.NotificationManager.getLatestMessage(success, error);
    } else {
      error('cannot find plugin');
    }
  });
});

_defineProperty(PkCordovaNotifications, "stopAudioAndVibration", function () {
  return new Promise(function (resolve, reject) {
    function success() {
      resolve();
    }

    function error(errorMessage) {
      reject(errorMessage);
    }

    var firebase = window.cordova.plugins.firebase;

    if (firebase && firebase.messaging) {
      window.cordova.plugins.firebase.messaging.stopCallSoundAndVibration().then(function () {
        success();
      })["catch"](function () {
        error('cannot stop audio and vibration');
      });
    } else {
      error('cannot find plugin');
    }
  });
});

var _default = PkCordovaNotifications;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaPayments = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaPayments = function PkCordovaPayments() {
  _classCallCheck(this, PkCordovaPayments);
};

exports.PkCordovaPayments = PkCordovaPayments;

_defineProperty(PkCordovaPayments, "askPaymentMethodNonce", function (token) {
  return new Promise(function (resolve, reject) {
    var methodNonce = window.cordova.plugins.PikkartPaymentBrainTree;
    methodNonce.getMethodNonce(token, success, error);

    function error(errorMessage) {
      reject(errorMessage);
    }

    function success(paymentMethodNonce) {
      resolve(paymentMethodNonce);
    }
  });
});

var _default = PkCordovaPayments;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaPermissions = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaPermissions = function PkCordovaPermissions() {
  _classCallCheck(this, PkCordovaPermissions);
};

exports.PkCordovaPermissions = PkCordovaPermissions;

_defineProperty(PkCordovaPermissions, "askCallPermissions", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions; // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest

    var list = [permissions.CAMERA, permissions.RECORD_AUDIO
    /*       permissions.WRITE_EXTERNAL_STORAGE,
           permissions.READ_EXTERNAL_STORAGE,
           permissions.ACCESS_NETWORK_STATE,
           permissions.INTERNET,
           permissions.ACCESS_WIFI_STATE,
           permissions.CHANGE_WIFI_STATE,
           permissions.ACCESS_COARSE_LOCATION,
           permissions.NETWORK_STATE*/
    ];
    permissions.checkPermission(list, success, error);

    function error(msg) {
      reject('Permissions are not turned on', msg);
    }

    function success(status) {
      if (!status.hasPermission) {
        permissions.requestPermissions(list, function (status) {
          if (!status.hasPermission) error();else {
            resolve(status);
          }
        }, error);
      }
    }
  });
});

_defineProperty(PkCordovaPermissions, "askCameraPermission", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions; // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest

    var list = [permissions.CAMERA];
    permissions.checkPermission(list, success, error);

    function error() {
      reject('Permissions are not turned on');
    }

    function success(status) {
      if (!status.hasPermission) {
        permissions.requestPermissions(list, function (status) {
          if (!status.hasPermission) error();else {
            resolve(status);
          }
        }, error);
      }
    }
  });
});

_defineProperty(PkCordovaPermissions, "askMicPermission", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions; // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest

    var list = [permissions.RECORD_AUDIO];
    permissions.checkPermission(list, success, error);

    function error() {
      reject('Permissions are not turned on');
    }

    function success(status) {
      if (!status.hasPermission) {
        permissions.requestPermissions(list, function (status) {
          if (!status.hasPermission) error();else {
            resolve(status);
          }
        }, error);
      }
    }
  });
});

_defineProperty(PkCordovaPermissions, "askStoragePermission", function () {
  return new Promise(function (resolve, reject) {
    if (window.cordova.platformId === "android") {
      var error = function error() {
        reject('Permissions are not turned on');
      };

      var success = function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(list, function (status) {
            if (!status.hasPermission) error();else {
              resolve(status);
            }
          }, error);
        } else {
          resolve(status);
        }
      };

      var permissions = window.cordova.plugins.permissions; // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest

      var list = [permissions.READ_EXTERNAL_STORAGE, permissions.WRITE_EXTERNAL_STORAGE];
      permissions.checkPermission(list, success, error);
    } else {
      resolve(status);
    }
  });
});

_defineProperty(PkCordovaPermissions, "askArPermissions", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions; // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest

    var list = [permissions.CAMERA, permissions.READ_EXTERNAL_STORAGE, permissions.WRITE_EXTERNAL_STORAGE];
    permissions.checkPermission(list, success, error);

    function error() {
      reject('Permissions are not turned on');
    }

    function success(status) {
      if (!status.hasPermission) {
        permissions.requestPermissions(list, function (status) {
          if (!status.hasPermission) error();else {
            resolve(status);
          }
        }, error);
      }
    }
  });
});

_defineProperty(PkCordovaPermissions, "checkOverlayPermission", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions;
    permissions.checkOverlayPermission(success, error);

    function error() {
      reject('Overlay permission are not turned on');
    }

    function success(status) {
      resolve(status);
    }
  });
});

_defineProperty(PkCordovaPermissions, "askOverlayPermission", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions;
    permissions.requestOverlayPermission(success, error);

    function error() {
      reject('Permissions are not turned on');
    }

    function success(status) {
      if (!status.hasPermission) {
        error();
      } else {
        resolve(status);
      }
    }
  });
});

_defineProperty(PkCordovaPermissions, "checkOpenFromBackgroundPermission", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions;
    permissions.checkOpenFromBackgroundPermission(success, error);

    function error() {
      reject('Open from background permission are not turned on');
    }

    function success(status) {
      resolve(status);
    }
  });
});

_defineProperty(PkCordovaPermissions, "askOpenFromBackgroundPermission", function () {
  return new Promise(function (resolve, reject) {
    var permissions = window.cordova.plugins.permissions;
    permissions.requestOpenFromBackgroundPermission(success, error);

    function error() {
      reject('Permissions are not turned on');
    }

    function success(status) {
      if (!status.hasPermission) {
        error();
      } else {
        resolve(status);
      }
    }
  });
});

var _default = PkCordovaPermissions;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaSharing = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaSharing = function PkCordovaSharing() {
  _classCallCheck(this, PkCordovaSharing);
};

exports.PkCordovaSharing = PkCordovaSharing;

_defineProperty(PkCordovaSharing, "shareWithOptions", function (options) {
  return new Promise(function (resolve, reject) {
    var onSuccess = function onSuccess(result) {
      //console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
      //console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      resolve(result);
    };

    var onError = function onError(msg) {
      reject("Sharing failed with message: " + msg);
    };

    window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
  });
});

_defineProperty(PkCordovaSharing, "share", function (message, subject, imageUrl, url) {
  return new Promise(function (resolve, reject) {
    var onSuccess = function onSuccess(result) {
      //console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
      //console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      resolve(result);
    };

    var onError = function onError(msg) {
      reject("Sharing failed with message: " + msg);
    };

    if (url !== "" && !url.match(/^[a-zA-Z]+:\/\//)) {
      url = 'https://' + url;
    }

    window.plugins.socialsharing.share(message, subject, imageUrl, url, onSuccess, onError);
  });
});

var _default = PkCordovaSharing;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.PkCordovaUi = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PkCordovaUi = function PkCordovaUi() {
  _classCallCheck(this, PkCordovaUi);
};

exports.PkCordovaUi = PkCordovaUi;

_defineProperty(PkCordovaUi, "openDocumentFromLink", function (docName, url) {
  var dataURItoBlob = function dataURItoBlob(dataURI) {
    fetch(dataURI).then(function (res) {
      return res.blob();
    }) // Gets the response and returns it as a blob
    .then(function (blob) {
      var download = function download(filename, data, mimeType) {
        var blob = new Blob([data], {
          type: mimeType
        });

        if (window.cordova && window.cordova.platformId !== "browser") {
          document.addEventListener("deviceready", function () {
            var storageLocation = "";

            switch (window.device.platform) {
              case "Android":
                storageLocation = window.cordova.file.externalDataDirectory;
                break;

              case "iOS":
                storageLocation = window.cordova.file.documentsDirectory;
                break;
            }

            var folderPath = storageLocation;
            window.resolveLocalFileSystemURL(folderPath, function (dir) {
              dir.getFile(filename, {
                create: true
              }, function (file) {
                file.createWriter(function (fileWriter) {
                  fileWriter.write(blob);

                  fileWriter.onwriteend = function () {
                    var url = file.toURL();
                    window.cordova.plugins.fileOpener2.open(url, mimeType, {
                      error: function error(err) {
                        console.error(err);
                        alert("Unable to download");
                      },
                      success: function success() {
                        console.log("success with opening the file");
                      }
                    });
                  };

                  fileWriter.onerror = function (err) {
                    alert("Unable to download");
                    console.error(err);
                  };
                }, function (err) {
                  // failed
                  alert("Unable to download");
                  console.error(err);
                });
              }, function (err) {
                alert("Unable to download");
                console.error(err);
              });
            }, function (err) {
              alert("Unable to download");
              console.error(err);
            });
          });
        }
        /*else {
        saveAs(blob, filename);
        }*/

      };

      download(docName, blob, blob.type);
    });
  };

  dataURItoBlob(url);
});

_defineProperty(PkCordovaUi, "lockOrientationInLandscape", function () {
  window.screen.orientation.lock('landscape');
  /*screen.orientation.lock('landscape').then((locked) => {
    console.log('orientation landscape locked ' + locked)
  }).catch((error) => {
    console.log('orientation error ' + error)
  })*/
});

_defineProperty(PkCordovaUi, "lockOrientationInPortrait", function () {
  window.screen.orientation.lock('portrait');
  /*screen.orientation.lock('portrait').then((locked) => {
    console.log('orientation portrait locked ' + locked)
  }).catch((error) => {
    console.log('orientation error ' + error)
  })*/
});

_defineProperty(PkCordovaUi, "unlockOrientation", function () {
  window.screen.orientation.unlock();
});

var _default = PkCordovaUi;
exports["default"] = _default;
