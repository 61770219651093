import React from 'react';
import { Swiper, SwiperSlide, BlockTitle } from 'framework7-react';
import { PkCmsMarkers } from 'pikkart-cms';

class MarkerSwiper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            markersImages: [],
        };
    }

    render() {

        const slidesPerView = 3//(this.state.markersImages.length < 3 ? this.state.markersImages.length : 3)


        const swiper = (
            this.state.markersImages.length > 0 ?
                <div onClick={() => { this.onMarkersClick() }}>
                    <BlockTitle medium>
                        {this.props.markerSwiperswiperTitle}
                    </BlockTitle>
                    <Swiper
                        className="galleria"
                        pagination
                        params={{
                            slidesPerView: slidesPerView,
                            spaceBetween: 10,
                        }}>
                        {this.getMarkersImages()}
                    </Swiper>
                </div>
                : <Swiper></Swiper>)
        return (
            <div>
                {swiper}
            </div>
        )
    }

    getMarkersImages = () => {
        const ret = this.state.markersImages.map((marker, index) => {
            const gImageUrl = (marker.imageUrl && marker.imageUrl !== "") ? marker.imageUrl : 'https://picsum.photos/200';

            return (
                <SwiperSlide key={'image-' + index} style={{ backgroundImage: "url(" + gImageUrl + ")" }} />
            )
        });

        return ret;
    }

    syncCloudMarkers = () => {
        const self = this;

        PkCmsMarkers.syncCloudMarkers(this.props.clientId)
            .then((result) => {

                if (result.data) {
                    console.log('sync markers', result.data);
                    self.setState({ markersImages: result.data })
                }
            }).catch(() => {
                console.log('sync markers error');
            });
    }

    onMarkersClick = () => {
        this.props.onMarkersClick();
    }

    componentDidMount = () => {
        this.syncCloudMarkers();
    }
}
export default MarkerSwiper;