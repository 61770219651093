import React from 'react';
import { RaLog } from 'pikkart-remote-assistance';

class MapView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zoomLevel: 18,
        };
    }

    render() {
        return (
            <div id={this.getMapId(this.props.content)} style={{ height: '150px' }}></div>
        )
    }

    componentDidMount = () => {
        this.createGoogleMapWithMarkers(this.props.content);
    }

    getMapId = (content) => {
        return 'map_' + content.id;
    }

    createGoogleMapWithMarkers = (content) => {
        let map = this.createGoogleMap(content);

        var bounds = new window.google.maps.LatLngBounds();
        var infowindow = new window.google.maps.InfoWindow();

        if (content.lat && content.long) {
            this.addContentMarkerToMap(map, bounds, infowindow, content);
        }
    }

    createGoogleMap = (content) => {
        return new window.google.maps.Map(document.getElementById(this.getMapId(content)), {
            center: { lat: content.lat, lng: content.long },
            zoom: this.state.zoomLevel
        });
    }

    addContentMarkerToMap = (map, bounds, infowindow, relatedContent) => {
        const self = this;

        const position = { lat: relatedContent.lat, lng: relatedContent.long };

        RaLog.log('Add marker to map', position);
        const marker = new window.google.maps.Marker({ position: position, map: map });

        //extend the bounds to include each marker's position
        bounds.extend(marker.position);

        window.google.maps.event.addListener(marker, 'click', (function (marker, relatedContent) {
            return function () {
                infowindow.setContent(relatedContent.title);
                infowindow.open(map, marker);
            }
        })(marker, relatedContent));

        //now fit the map to the newly inclusive bounds
        map.fitBounds(bounds);

        //(optional) restore the zoom level after the map is done scaling
        const listener = window.google.maps.event.addListener(map, "idle", function () {
            map.setZoom(self.state.zoomLevel);
            window.google.maps.event.removeListener(listener);
        });
    }
};

export default MapView;