import React from 'react';
import { f7, Navbar, Page, LoginScreenTitle, ListItem, Button, Link, List, ListInput, PageContent, BlockFooter, Icon } from 'framework7-react';
import { connect } from 'react-redux';
import { RaApiAuth, RaLog } from 'pikkart-remote-assistance';
import { PkCordova, PkCordovaAuthentication } from 'pikkart-cordova';
import localeStrings from './login-local'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import AnalyticsRegister, { ANALYTICS_SCREEN } from '../../analytics-register';
import { browserName, mobileVendor, mobileModel } from 'react-device-detect';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            usernamevalid: false,
            selectedCustomer: null,
            customers: [],
            confirmcode: '',
            errormessage: null,
            deviceId: null,
        };
    }

    render() {
        const signinmessage = (this.state.usernamevalid === false || this.state.selectedCustomer == null ? localeStrings.next : localeStrings.login);

        let li = (
            <ListInput
                inputId="emailli"
                floatingLabel={true}
                label={localeStrings.input_label_email}
                type="email"
                validate={true}
                placeholder={localeStrings.sample_email}
                value={this.state.username === null ? '' : this.state.username}
                onInput={(e) => {
                    const username = (e.target.value === "" ? null : e.target.value);
                    this.setState({ username: username });
                }}
            />);

        //Framework7 ha comportamenti diversi in base a se inserisco direttamente
        //un suo component nella pagina o se faccio un functional component
        /*<EmailListInput
            label={localeStrings.input_label_email}
            placeholder={localeStrings.sample_email}
            username={this.state.username === null ? '' : this.state.username}
            onInputHandler={(e) => {
                const username = (e.target.value === "" ? null : e.target.value);
                this.setState({ username: username });
            }}
        />*/


        if (this.state.usernamevalid === true) {
            if (this.state.selectedCustomer == null) {
                li = this.state.customers.map((item, index) => {

                    return (
                        <ListItem
                            key={"liCustomer" + index}
                            title={item.Name}
                            onClick={(e) => { this.selectCustomer(item); }}
                            link="#">
                            <Icon slot="media" icon="demo-list-icon"></Icon>
                        </ListItem>
                    );

                });
            }
            else {
                li = (
                    <ListInput
                        label={localeStrings.input_label_code}
                        type="tel"
                        placeholder="******"
                        floatingLabel={true}
                        value={this.state.confirmcode === null ? '' : this.state.confirmcode}
                        onInput={(e) => {
                            this.setState({ confirmcode: e.target.value });
                        }}
                    />
                );
            }
        }

        let backButton = <Link onClick={(e) => { this.resetCredentials() }} >{localeStrings.change_email_label}</Link>;

        if (this.state.usernamevalid === false) {
            backButton = null;
        }
        return (
            <Page noSwipeback loginScreen>
                <Navbar>
                    <PikkartNavbar
                        title={localeStrings.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                </Navbar>
                <PageContent>
                    <LoginScreenTitle>{localeStrings.signIn}</LoginScreenTitle>
                    <List id="login-form"
                        mediaList={this.state.usernamevalid === false || this.state.selectedCustomer != null}
                        form={this.state.usernamevalid && this.state.selectedCustomer == null} >
                        {li}
                        <BlockFooter>
                            <div style={{ textAlign: 'right' }}>
                                {this.state.usernamevalid === true && this.state.selectedCustomer != null ?
                                    <Link iconIos="f7:arrow_clockwise" iconAurora="f7:arrow_clockwise" iconMd="f7:arrow_clockwise" onClick={(e) => { this.sendCode(this.state.deviceId) }}>{localeStrings.resend_code_label}</Link>
                                    :
                                    null}
                            </div>
                            <br></br><br></br>
                            <p style={{ textAlign: 'center' }}>{backButton}</p>
                            <Button fill onClick={(e) => { this.signIn() }}>{signinmessage}</Button>
                            <p style={{ color: 'red' }}>{this.state.errormessage ? this.state.errormessage.message : null}</p>
                            <p style={{ display: this.state.usernamevalid === true ? 'none' : 'block' }}>{localeStrings.description_signin}</p>
                            <p style={{ display: this.state.usernamevalid === true && this.state.selectedCustomer == null ? 'block' : 'none' }}>{localeStrings.description_selectCompany}</p>
                            <p style={{ display: this.state.usernamevalid === true && this.state.selectedCustomer != null ? 'block' : 'none' }}>{localeStrings.description_code} </p>

                        </BlockFooter>
                    </List>
                </PageContent>
            </Page >
        )
    }

    selectCustomer = (item) => {
        RaLog.log(item);
        const self = this;
        this.setState({ selectedCustomer: item }, () => {
            self.sendCode(this.state.deviceId);
        });
    }

    resetCredentials = () => {
        this.setState({
            username: null,
            usernamevalid: false,
            confirmcode: null,
            errormessage: null,
            selectedCustomer: null
        });
    }

    sendCode = (deviceId) => {

        const errorFn = (error) => {
            let errorMessage = error.errorMsg;

            /*this.setState({
                errormessage: { message: errorMessage }
            });*/

            if (error.errorCode && error.errorCode === 403) {
                errorMessage = localeStrings.max_device_number_message;
            }

            this.showToastBottom(errorMessage);
        }

        const successFn = () => {
            this.setState({
                errormessage: localeStrings.code_sent + " " + this.state.username,
            });

            this.showToastBottom(localeStrings.code_sent + " " + this.state.username);
        }

        RaApiAuth.sendNewCode(this.state.username, this.state.selectedCustomer.Code, deviceId)
            .then(function (response) {
                //handle success
                RaLog.log(response);

                if (response.result.success === true) {
                    // handle success
                    successFn();
                } else {
                    // handle error
                    errorFn({ errorCode: response.result.code, errorMsg: response.result.message });
                }
            })
            .catch(function (errorMessage) {
                //handle error
                errorFn({ errorMsg: errorMessage });
            });
    }

    signIn = () => {
        if (this.state.usernamevalid === false) {
            const emailli = document.getElementById("emailli");

            if (emailli !== null && emailli.value && emailli.validity.valid === true && this.state.username !== null) {
                const self = this;


                if (PkCordova.isCordova()) {
                    if (PkCordovaAuthentication.getDeviceId().then((deviceId) => {
                        self.setState({ deviceId: deviceId });
                        self.startCheckEmail(deviceId);
                    }));
                }
                else {
                    this.startCheckEmail(null);
                }
            }

            return;
        }

        if (this.state.confirmcode !== null) {
            this.showPreloader(true);
            this.performLogin();
        }
    }

    startCheckEmail = (deviceId) => {
        const self = this;

        this.checkEmail(deviceId).then(function (customers) {
            self.showPreloader(false);
            if (customers.length === 1) {
                self.setState({ usernamevalid: true, customers: customers, selectedCustomer: customers[0], errormessage: null });
                self.sendCode(self.state.deviceId);
            }
            else if (customers.length >= 1) {
                self.setState({ usernamevalid: true, customers: customers, selectedCustomer: null, errormessage: null });
            }
            else {
                self.setState({ usernamevalid: false, customers: [], selectedCustomer: null, errormessage: { message: localeStrings.unknown_email } });
            }
        })
            .catch(function (error) {

                console.log('raggiunto numero massimo di device', error.errorMsg);

                RaLog.log(error.errorMsg);
                if (error.errorMsg !== self.state.errormessage) {

                    let errorMessage = error.errorMsg;

                    if (error.errorCode && error.errorCode === 403) {
                        errorMessage = localeStrings.max_device_number_message;
                    }

                    self.setState({
                        errormessage: { message: errorMessage }
                    });
                }

                self.showPreloader(false);
            });
    }

    checkEmail = (deviceId) => {
        return new Promise((resolve, reject) => {

            this.showPreloader(true);

            const errorFn = (error) => {
                reject(error);
            }

            const successFn = (customers) => {
                resolve(customers);
            }

            RaApiAuth.checkEmail(this.state.username, deviceId)
                .then(function (response) {

                    if (response.result.success === true) {
                        // handle success
                        successFn(response.data);

                    } else {
                        // handle error
                        errorFn({ errorCode: response.result.code, errorMsg: response.result.message });
                    }
                })
                .catch(function (errorMessage) {
                    //handle error
                    errorFn({ errorMsg: errorMessage });
                });

        });
    };

    performLogin = () => {
        const errorFn = (errorMessage, code) => {
            if (code === 403) {
                errorMessage = localeStrings.invalid_code_message;
            }

            if (errorMessage !== this.state.errormessage) {
                this.setState({
                    errormessage: errorMessage
                });
            }

            this.showPreloader(false);
            this.showToastBottom(errorMessage);
        }

        const successFn = () => {
            this.showPreloader(false);
            this.resetCredentials();
        }

        const hidePreloader = () => {
            this.showPreloader(false);
        }

        const doLogin = (deviceId, deviceModel) => {
            RaApiAuth.doLogin(this.state.username, this.state.confirmcode, deviceId, this.state.selectedCustomer, deviceModel)
                .then(function (response) {

                    if (response.result.success === true) {
                        successFn();
                        // handle success
                    } else {
                        // handle error
                        errorFn(response.result.message, response.result.code);
                    }
                    hidePreloader();
                })
                .catch(function (errorMessage) {
                    RaLog.log("FIREBASE TOKEN to server: error " + errorMessage);
                    //handle error
                    errorFn(errorMessage);
                });
        }

        if (PkCordova.isCordova()) {
            if (PkCordovaAuthentication.getDeviceId().then((deviceId) => {
                const deviceModel = mobileVendor + " " + mobileModel;
                doLogin(deviceId, deviceModel);
            }));
        }
        else {
            doLogin("1234567890", browserName);
        }
    };

    showToastBottom = (text) => {
        this.toastBottom = f7.toast.create({
            text: text,
            closeTimeout: 2000,
        });
        this.toastBottom.open();
    };

    showPreloader = (show) => {
        if (show) {
            f7.preloader.show();
        }
        else {
            f7.preloader.hide();
        }
    }

    isUserAuthenticated = () => {
        const redirectPage = this.props.redirectPage;

        if (RaApiAuth.isAuthenticated() && this.props.f7router.currentRoute.url !== redirectPage) {
            this.showPreloader(false);
            this.props.f7router.navigate(redirectPage, { reloadAll: true, props: { fade: true } });
            return true;
        }
        return false;
    }

    componentDidMount = () => {

        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.LOGIN);

        if (this.isUserAuthenticated()) {
            return;
        }

        const node = document.getElementsByClassName("item-input")[0];
        const self = this;
        if (node) {
            node.addEventListener("keyup", ({ key }) => {
                if (key === "Enter") {
                    self.signIn();
                }
            })
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.isUserAuthenticated()) {
            return;
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        loggedIn: state.remoteAssistance.loggedIn,
        firebaseToken: state.remoteAssistance.firebaseToken,
        isCordovaReady: state.remoteAssistance.isCordovaReady,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Login);
