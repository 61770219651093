import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, Link } from 'framework7-react';
import { PkCmsContents } from 'pikkart-cms';
import ContentListItem from './content-list-item';
import Commons from '../commons'
import appLocaleStrings from '../app-local'
import PikkartNavbar from '../components/pk-navbar/pk-navbar';
import AnalyticsRegister, { ANALYTICS_EVENT } from '../analytics-register';
import { CONTENT_VIEW } from 'pikkart-cms';
import MobileDetect from 'mobile-detect';

class ContentList extends React.Component {

    constructor(props) {
        super(props);

        this.page = 0;
        this.pageSize = 10;

        this.state = {
            errorMessage: null,
            infoContentsData: [],
            filteredInfoContentsData: [],
            allowInfinite: true,
            showPreloader: true,
            enableSearchBar: false,
        };
    }

    render() {

        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        return (
            <Page
                infinite
                infinitePreloader={this.state.showPreloader}
                onInfinite={() => this.fetchInfoContents()}
            >
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        searchBarEnabled={JSON.parse(process.env.REACT_APP_ENABLE_SEARCH_BAR) && this.state.enableSearchBar}
                        searchBarId={'searchbar-' + menuItem.id}
                        onSearch={this.onSearchBarSearchHandler} />
                </Navbar>

                <div>
                    {this.getInfoContentsData()}
                </div>

            </Page>
        )
    }

    getBackButton = (item) => {
        if (item.catIndex > 1) {
            return (<Link back iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" ></Link>);
        }
        else {
            return (
                <img slot="media" src='./img/logo/logo.png'
                    className="icon material-icons" style={{ fontSize: 'xxx-large', paddingRight: '10px' }} alt='app_icon' />
            )
        }
    }


    getInfoContentsData = () => {

        if (this.state.infoContentsData === null ||
            this.state.infoContentsData.length === 0) {
            if (this.state.errorMessage) {
                return (

                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }

        const ret = this.state.filteredInfoContentsData.map((item, index) => {
            let brandLine = "";
            let skinColor = '#000000';
            let colorFont = '#FFFFFF';
            let brandName = '';

            if (item.brand) {
                brandName = item.brand.name;
            }
            if (item.skin) {
                skinColor = item.skin.hdBgColor
                colorFont = item.skin.hdFontColor
            }

            if (item.brand || item.skin) {
                brandLine = (<div className="categoria" style={{ borderBottomColor: skinColor }}>
                    <span style={{ color: colorFont, backgroundColor: skinColor }}>{brandName}</span>
                </div>)
            }

            // return (<ContentListItem
            //     contentData={item}
            //     key={'info-content-' + index} onCardClickHandler={(item) => {
            //         this.onCardClick(item);
            //     }}
            //     onInfoClickHandler={(params) => {
            //         this.onInfoClick(params);
            //     }} />)
            const md = new MobileDetect(window.navigator.userAgent);
            if (md.phone()) {
                return (
                    <div key={'info-content-' + index}>
                        {brandLine}
                        <ContentListItem
                            contentData={item}
                            key={'info-content-' + index} onCardClickHandler={(item) => {
                                this.onCardClick(item);
                            }}
                            onInfoClickHandler={(params) => {
                                this.onInfoClick(params);
                            }} />
                    </div>
                )
            }
            else {
                let cardWidth = null;
                if (window.outerWidth < window.outerHeight) { //portrait
                    if (window.outerWidth < 770) {
                        cardWidth = window.outerWidth / 100 * 46.13
                    }
                    if (window.outerWidth < 835) {
                        cardWidth = window.outerWidth / 100 * 46.39
                    }
                    else if (window.outerWidth < 1025) {
                        cardWidth = window.outerWidth / 100 * 22.57
                    }
                }
                else { //landscape
                    if (window.outerWidth < 1025) {
                        cardWidth = window.outerWidth / 100 * 22.57
                    }
                    if (window.outerWidth < 1115) {
                        cardWidth = window.outerWidth / 100 * 22.57
                    }
                    else if (window.outerWidth < 1370) {
                        cardWidth = window.outerWidth / 100 * 22.75
                    }
                    else if (window.outerWidth < 1700) {
                        cardWidth = window.outerWidth / 100 * 23.86
                    }
                }

                return (
                    <div key={'info-content-' + index}>
                        {brandLine}
                        <ContentListItem
                            cardWidth={cardWidth}
                            contentData={item}
                            key={'info-content-' + index} onCardClickHandler={(item) => {
                                this.onCardClick(item);
                            }}
                            onInfoClickHandler={(params) => {
                                this.onInfoClick(params);
                            }} />
                    </div>
                )
            }
        });

        if (this.state.infoContentsData && this.state.infoContentsData.length > 0 && this.state.infoContentsData[0].content) {
            this.checkEnableSearchBar(this.state.infoContentsData[0].content.ctnView);
        }


        return (
            <Block style={{ padding: '0' }}>
                <div className="demo-expandable-cards">{ret}</div>
                {/*<PageContent style={{ padding: '0' }}>{ret}</PageContent>*/}
            </Block>)
    }

    checkEnableSearchBar = (content) => {
        let enableSearchBar = false;
        switch (content) {
            case CONTENT_VIEW.DISCOVER_MODEL:
                enableSearchBar = true;
                break;
            case CONTENT_VIEW.INFOCONTENT:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.MARKERLESS:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.DISCOVER_KEYPOINT:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.PLACE:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.ITINERARY:
                enableSearchBar = false;
                break;
            case CONTENT_VIEW.AR_SCENE:
                enableSearchBar = true;
                break;
            case -12: //lastre asset recognition
                enableSearchBar = false;
                break;
            default:
                break;
        }

        if (this.state.enableSearchBar !== enableSearchBar) {
            this.setState({ enableSearchBar: enableSearchBar })
        }
    }

    onCardClick = (item) => {
        this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CONTENT_DETAIL, item)
    }

    onInfoClick = (params) => {
        if (this.props.f7router.currentRoute.url !== "/contact") {
            this.props.f7router.navigate('/contact', {
                props: {
                    showNavRight: true,
                    showArrowAsNavRight: true,
                    precompiledBrandName: params.brandName,
                    precompiledNoteInfo: params.contentTitle
                }
            });
        }
    }

    registerAnalyticsEvent = (eventName, contentItem) => {
        if (contentItem.content && contentItem.content.title && contentItem.content.id)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: contentItem.content.title,
                    ContentId: contentItem.content.id,
                    ContentType: Commons.getStringContentView(contentItem.content.ctnView),
                });
    }

    componentDidMount = () => {

        //TODO: una volta fatto il componente separato i dati gli vengono passati indipendentemente dal fatto che siano in locale o da scaricare
        if (this.props.menuItem.code === '/preferiti') {
            const jsonSringFavoriteContents = localStorage.getItem('favorite_contents');
            if (jsonSringFavoriteContents) {
                const favoritesContents = JSON.parse(jsonSringFavoriteContents);
                if (favoritesContents.length > 0) {
                    this.setState({ infoContentsData: favoritesContents, errorMessage: null, showPreloader: false, allowInfinite: false });
                }
                else {
                    this.noFavoritesContents();
                }
            }
            else {
                this.noFavoritesContents();
            }
        }
        else if (this.state.infoContentsData === null || this.state.infoContentsData.length === 0) {
            this.fetchInfoContents();
        }

        window.addEventListener("resize", this.setScreenOrientation);
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.menuItem.code === '/preferiti') {
            if (this.props.favoriteContents && this.props.favoriteContents !== prevProps.favoriteContents) {
                if (this.props.favoriteContents.length > 0) {
                    this.setState({ infoContentsData: this.props.favoriteContents, errorMessage: null, showPreloader: false, allowInfinite: false });
                }
                else {
                    this.noFavoritesContents();
                }
            }
        }
    }

    noFavoritesContents = () => {
        this.setState({
            showPreloader: false,
            allowInfinite: false,
            errorMessage: appLocaleStrings.no_contents,
            infoContentsData: this.props.favoriteContents,
            // infoContentsData: []
        });
    }

    fetchInfoContents = () => {
        if (!this.state.allowInfinite) return;
        this.setState({ allowInfinite: false });

        this.page += 1;

        const errorFn = (errorMsg) => {
            if (this.page !== 1) {
                this.setState({ showPreloader: false });
                return;
            }

            this.setState({ errorMessage: errorMsg, allowInfinite: true, showPreloader: false });
        }

        const successFn = (infoContentsData) => {
            console.log('infoContentsData ', infoContentsData)
            if (this.props.menuItem.code === '/result') {
                infoContentsData = infoContentsData.map((item, index) => {
                    item.content.ctnView = -12;

                    return item;
                });
            }


            const updatedContentsData = this.state.infoContentsData.concat(infoContentsData);
            this.setState({
                infoContentsData: updatedContentsData,
                filteredInfoContentsData: updatedContentsData,
            });

            this.setState({ errorMessage: null, showPreloader: false });

            if (infoContentsData.length === this.pageSize)
                this.setState({
                    allowInfinite: true,
                });
        }

        PkCmsContents.getInfoContentsDataPage({ c1: this.props.c1, c2: this.props.c2, c3: this.props.c3, page: this.page, pageSize: this.pageSize })
            .then((response) => {
                if (response.result.success === true) {
                    if (response.data.items.length === 0) {
                        if (this.state.infoContentsData.length > 0) {
                            this.setState({ showPreloader: false });
                            return;
                        }
                        errorFn(appLocaleStrings.no_contents);
                    }
                    else
                        successFn(response.data.items);
                } else {
                    errorFn(response.result.message);
                }
            })
            .catch((errorMessage) => {
                errorFn(errorMessage);
            });
    };

    onSearchBarSearchHandler = (searchbar, query, previousQuery) => {
        if (query) {

            let updatedContentsData = [];

            let infoContentsData = this.state.infoContentsData;
            infoContentsData.forEach(item => {
                if (item.content.title.toLowerCase().includes(query.toLowerCase())) {
                    updatedContentsData.push(item);
                }
            });

            this.setState({ filteredInfoContentsData: updatedContentsData })
        }
        else {
            this.setState({ filteredInfoContentsData: this.state.infoContentsData })
        }
    }

    setScreenOrientation = (orientation) => {
        //console.log(orientation)
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        favoriteContents: state.app.favoriteContents
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);