
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    search_placeholder_text: 'Search',
  },
  it: {
    search_placeholder_text: 'Cerca',
  }
});
  
  export default localeStrings;