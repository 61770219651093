import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        name: "Name",
        name_placeholder: "Your name",
        surname: "Surname",
        surname_placeholder: "Your surname",
        email: "Email",
        email_placeholder: "Your e-mail",
        city: "City",
        city_placeholder: "Enter your city",
        address_zip: "ZIP Code",
        address_zip_placeholder: "Enter your ZIP code",
        province: "Province",
        province_placeholder: "Enter your province",
        country: "Country",
        country_placeholder: "Enter your country",
        profession: "Profession",
        profession_placeholder: "Enter your profession",
        phone: "Phone",
        phone_placeholder: "Enter your phone",
        mobile_phone: "Mobile Phone",
        mobile_phone_placeholder: "Enter your mobile phone",
        privacy: "Privacy",
        privacy_text: "I hereby confirm that i have read understood and accepted the terms of the information on the processing of personal data",
        privacy_link_text: "Go to the privacy page",
        privacy_site: "https://www.irisceramicagroup.com/en/terms-conditions/",
        privacy_not_checked_error: "Please check the privacy box to continue",
        newsletter: "Newsletter",
        newsletter_text: "I want to receive Ariostea's newsletter.",
        subject: "Subject",
        subject_placeholder: "Enter the subject",
        message: "Message",
        message_label_header: "- I WOULD LIKE INFORMATION ABOUT:",
        message_placeholder: "Enter your message",
        submit_button_text: "Submit",
        info_validation_text: "* required field",
        required_validation_text: "*",
        required_validation_empty_text: "Please fill out this field",
        required_validation_empty_list_text: "Please select an item in the list",
        email_format_wrong_text: "Email format not valid",
        format_wrong_text: "Format not valid",
        not_valid_fields_error: "Some fields have not been entered correctly",
        profession_architect: "Architect/Designer",
        profession_construction_industry: "Construction Industry",
        profession_dealer: "Dealer",
        profession_engineer: "Engineer",
        profession_tile_worker: "Marble/Tile worker",
        profession_private_customer: "Private Customer",
        contact_from_error_title: "Sending data failed",
        contact_from_error_text: "There was an error sending your data",
        contact_from_success_title: "Data sent",
        contact_from_success_text: "The data was sent successfully",
    },
    it: {
        name: "Nome",
        name_placeholder: "Il tuo nome",
        surname: "Cognome",
        surname_placeholder: "Il tuo cognome",
        email: "Email",
        email_placeholder: "La tua e-mail",
        city: "Città",
        city_placeholder: "Inserisci la città",
        address_zip: "CAP",
        address_zip_placeholder: "Inserisci il CAP",
        province: "Provincia",
        province_placeholder: "Inserisci la provincia",
        country: "Nazione",
        country_placeholder: "Inserisci la nazione",
        profession: "Professione",
        profession_placeholder: "Inserisci la professione",
        phone: "Telefono",
        phone_placeholder: "Inserisci il numero di telefono",
        mobile_phone: "Cellulare",
        mobile_phone_placeholder: "Inserisci il numero di cellulare",
        privacy: "Privacy",
        privacy_text: "Con questo confermo che ho letto compreso ed accettato i termini dell'Informativa sul trattamento dei dati personali",
        privacy_link_text: "Vai alla pagina della privacy",
        privacy_site: "https://www.irisceramicagroup.com/terms-conditions/",
        privacy_not_checked_error: "Accetta i termini della privacy per continuare",
        newsletter: "Newsletter",
        newsletter_text: "Desidero ricevere la newsletter di Ariostea.",
        subject: "Oggetto",
        subject_placeholder: "Inserisci l'oggetto",
        message: "Messaggio",
        message_label_header: "- DESIDERO INFORMAZIONI SU:",
        message_placeholder: "Inserisci il tuo messaggio",
        submit_button_text: "Invia",
        info_validation_text: "* campo obbligatorio",
        required_validation_text: "*",
        required_validation_empty_text: "Compila questo campo",
        required_validation_empty_list_text: "Seleziona un elemento della lista",
        email_format_wrong_text: "Formato email non valido",
        format_wrong_text: "Formato non valido",
        not_valid_fields_error: "Alcuni campi non sono stati inseriti correttamente",
        profession_architect: "Architetto/Designer",
        profession_construction_industry: "Impresa Costruzioni",
        profession_dealer: "Rivenditore",
        profession_engineer: "Ingegnere",
        profession_tile_worker: "Marmista/Posatore",
        profession_private_customer: "Consumatore Privato",
        contact_from_error_title: "Invio non riuscito",
        contact_from_error_text: "C'è stato un errore con l'invio dei tuoi dati",
        contact_from_success_title: "Dati inviati",
        contact_from_success_text: "I dati sono stati inviati correttamente",
    }
});

export default localeStrings;