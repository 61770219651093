import React from 'react';
import { Icon } from 'framework7-react';

export class IconHelper {
    static getMenuIcon = (code) => {
        let icon = "";
        switch (code) {
            case "/ar":
                icon = (<Icon slot="media" ios="f7:eye" aurora="f7:eye" md="f7:eye" />);
                break;
            case "/arscenes-nootp":
                icon = (<Icon slot="media" icon="f7:cube" aurora="f7:cube" md="f7:cube" />);
                break;
            case "/arscenes":
                icon = (<Icon slot="media" ios="f7:cube" aurora="f7:cube" md="f7:cube" />);
                break;
            case process.env.REACT_APP_DEFAULT_CODE_PAGE:
                icon = (<Icon slot="media" ios="f7:search" aurora="f7:search" md="f7:search" />);
                break;
            case "/info-news":
                icon = (<Icon slot="media" ios="f7:house_alt" aurora="f7:house_alt" md="f7:house_alt" />);
                break;
            case "/tutorial":
                icon = (<Icon slot="media" ios="f7:book" aurora="f7:book" md="f7:book" />);
                break;
            case "/credits":
                icon = (<Icon slot="media" ios="f7:bag" aurora="f7:bag" md="f7:bag" />);
                break;
            case "/contact":
                icon = (<Icon slot="media" ios="f7:info_circle" aurora="f7:info_circle" md="f7:info_circle" />);
                break;
            case "/result":
                icon = (<Icon slot="media" ios="f7:search" aurora="f7:search" md="f7:search" />);
                break;
            case "/asset-recognition":
                icon = (<Icon slot="media" ios="f7:viewfinder_circle" aurora="f7:viewfinder_circle" md="f7:viewfinder_circle" />);
                break;
            case "/brands":
                icon = (<Icon slot="media" ios="f7:flag" aurora="f7:flag" md="f7:flag" />);
                break;
            default:
                break;
        }
        return icon;
    }
}

export default IconHelper;