import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        title: "Tutorial",
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start the tour",
        tutorial_1: "Frame the plate. If you want, use the options to adjust brightness, enable color recognition, and write notes about what you are shooting",
        tutorial_2: "Take a picture and discover the surfaces most similar to what you framed",
    },
    it: {
        title: "Tutorial",
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Comincia la visita",
        tutorial_1: "Inquadra la lastra. Se vuoi usa le opzioni per regolare la luminosità, abilitare il riconoscimento per colore e scrivere delle note su ciò che stai inquadrando",
        tutorial_2: "Scatta una foto e scopri le superfici più simili a ciò che hai inquadrato",
    }
});

export default localeStrings;